import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomRouteData } from 'src/app/shared/models/custom-routes.models';
import { PermissionService } from '../../auth/permission.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardService implements CanActivate {

  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) { }

  /**
   * Read permissions from route.data as CustomRouteData component and return true or
   * reroute
   * @param requestedRoute desired navigation routeSnapashot
   * @returns boolean on true, else navigate to posted reRoute url
   */
  canActivate(requestedRoute: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    // return true;
    let route: ActivatedRouteSnapshot | null = requestedRoute;

    // loop through parent routes to make sure they have the proper permissions
    let canAccess = true;
    let reRoute: string | undefined;
    do {
      const customData = route.routeConfig?.data as CustomRouteData | undefined;
      const permissions = customData?.permissions;
      const denyPermissions = customData?.denyPermissions;
      const allOrOne = customData?.allOrOne;
      reRoute = customData?.reRoute;

      canAccess = permissions ? this.permissionService.hasPermissions(permissions, denyPermissions, allOrOne) : canAccess;
      route = route.parent;
    } while (canAccess && route && route.routeConfig);

    if (!canAccess) {
      return reRoute ? this.router.parseUrl(reRoute) : this.router.parseUrl('/Portal/404');
    }
    return canAccess;
  }
}
