import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SureLcLicense } from 'src/app/shared/models/surelc-license';

@Injectable({
  providedIn: 'root'
})
export class SurelcApiService {

  constructor(private http:HttpClient) { }

  getStateLicenses():Observable<SureLcLicense[]> {
    return this.http.get<SureLcLicense[]>('/api/SureLc/Api/Agent/Licenses');
  }

}
