import { Component, OnInit } from '@angular/core';
import { SurelcApiService } from 'src/app/core/services/http/surelc-api.service';
import { stateLongNameByShortName } from 'src/app/shared/constants/states.constants';
import { SureLcLicense } from 'src/app/shared/models/surelc-license';


@Component({
  selector: 'app-contracting-licenses',
  templateUrl: './contracting-licenses.component.html',
})
export class ContractingLicensesComponent implements OnInit {
  licenses!: SureLcLicense[];
  stateLongNameByShortName: Record<string, string> = stateLongNameByShortName;

  constructor(
    private surelcApiService: SurelcApiService) {
  }

  ngOnInit(): void {
    this.surelcApiService.getStateLicenses().subscribe(r => {
      this.licenses = r;
    });
  }
}
